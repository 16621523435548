import styled from "styled-components";

export const KioskResStatus = styled.div`
  padding: 1.0833vw;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  .content {
    height: calc(100% - 11vw - 11vh);
    width: 100%;
    padding: 3px 20px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
  }
  .pagination {
    margin-top: 0;
    gap: 10px;
    display: flex;
    list-style: none;
    padding: 0;

    li {
      border-radius: 5px;
      transition: 0.3s;
      border: 3px solid #efefef;
      a {
        font-size: 22px;
        display: block;
        padding: 10px 15px;
        text-decoration: none;
      }
      &.disabled {
        background: #efefef;
        pointer-events: none;
      }
      &.active,
      &:hover:not(.disabled) {
        background: #2a3341;
        /* background: transparent; */
        border-color: #2a3341;
      }
    }
  }
  @media screen and (max-width: 820px) {
    header {
      height: 100px;
      flex-wrap: wrap;
      position: relative;
      .close {
        position: absolute;
        top: 1.0833vw;
        right: 1.0833vw;
      }
      .tit {
        width: 100vw;
        line-height: normal;
      }
      .searchForm {
        font-size: 14px;
        width: calc(100vw - 150px);
        height: 35px;
        input {
          padding: 0 60px 0 0;
          font-size: 20px;
        }
        button {
          font-size: 14px;
          padding: 5px 15px;
        }
      }
      .close {
        height: 40px;
        font-size: 40px;
      }
      .resStatusDate {
        font-size: 14px;
        > button {
          padding: 5px 15px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .content {
      padding: 0 10px;
      height: calc(100% - 20vh);
    }
  }
`;

export const DetailsUseInquiryModal = styled.div`
  position: fixed;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  .storageInfo {
    margin-top: 30px;
    width: 100%;
    height: calc(100% - 130px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
    > ul {
      width: 100%;
      > li {
        font-size: 20px;
        display: inline-block;
        width: calc(50% - 10px);
        border: 2px solid #000;
        padding: 5px;
        margin-bottom: 20px;
        border-radius: 10px;
        &:nth-child(odd) {
          margin-right: 20px;
        }
        .tit {
          display: inline-block;
          width: 120px;
        }
        .con {
          display: inline-block;
          width: calc(100% - 120px);
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .storageInfo > ul > li {
      width: 100%;
      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
`;

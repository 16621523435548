import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import axios from "axios";

import KioskHeader from "./component/common/kioskHeader";
import KioskFooter from "./component/common/kioskFooter";
import KioskTable from "./component/common/kioskTable";
import KioskList from "./component/common/kioskList";
import EditStatusModal from "./component/kioskPickUp/editStatusModal";

import { Container } from "./component/styled/common";
import { KioskPickUp } from "./component/styled/pickUp";

import { formatText, useDisableScroll } from "./component/function/kiosk";
import ReadyPickupModal from "./component/kioskPickUp/readyPickupModal";

const KioskPickUpPage = () => {
  useDisableScroll();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [searched, setSearched] = useState("");
  const [selected, setSelected] = useState({});
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    getEnableStorage();
  }, []);

  // 보관 중 조회
  const getEnableStorage = async () => {
    const url = "/api/reserve-user";
    const body = {
      reserveStatus: "COMPLETED",
    };
    const res = await axios.post(url, body);
    console.log(res);

    if (res.status === 200) {
      setOrigin(res.data);
      setData(res.data);
    }
  };

  // 검색
  const handleSearch = () => {
    const filteredData = origin.filter(
      (item) =>
        (item.mberNm && item.mberNm.includes(searched)) ||
        (item.tel && formatText(item.tel).includes(formatText(searched))),
    );

    setData(filteredData);
  };

  const handleModalOpen = async (status, item = null) => {
    if (status === "COMPLETED") {
      setSelected(item);
    }

    setModalType(status);
    setOpenModal(true);
  };

  const handleModalClose = async () => {
    await getEnableStorage();
    setOpenModal(false);
    setModalType("");
  };

  const getModalContent = () => {
    switch (modalType) {
      case "COMPLETED":
        return (
          <EditStatusModal
            selected={selected}
            handleModalClose={handleModalClose}
            // handleReserveCancel={handleReserveCancel}
          />
        );
      case "READY":
        return <ReadyPickupModal handleModalClose={handleModalClose} />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <KioskPickUp>
        <KioskHeader
          title={"찾기"}
          handleSearch={handleSearch}
          searched={searched}
          setSearched={setSearched}
        />
        <div className="content">
          <div className="pickupReqButton">
            <button className="pickupReqButton" onClick={() => handleModalOpen("READY")}>
              픽업요청확인
            </button>
          </div>
          {isTablet ? (
            <>
              <KioskList data={data} type="pickup" handleModalOpen={handleModalOpen} />
            </>
          ) : (
            <KioskTable data={data} type="pickup" handleModalOpen={handleModalOpen} />
          )}
        </div>

        {openModal && getModalContent()}
        <KioskFooter />
      </KioskPickUp>
    </Container>
  );
};

export default KioskPickUpPage;

import React from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import ko from "date-fns/locale/ko";
import "react-date-range/dist/theme/default.css";

const KioskCalendar = ({ handleDateClick, handleOpenCalendar, dateRange, handleResetDate }) => {
  const today = new Date();

  return (
    <div className="bg" onClick={(e) => e.target === e.currentTarget && handleOpenCalendar(false)}>
      <div className="calendar">
        <div className="calendarContent">
          <div className="reset">
            <button onClick={handleResetDate}>초기화</button>
          </div>
          <DateRangePicker
            ranges={[
              {
                startDate: dateRange.startDate || today,
                endDate: dateRange.endDate || today,
                key: "selection",
              },
            ]}
            onChange={handleDateClick}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            locale={ko}
            maxDate={today}
            shownDate={today}
          />
        </div>
      </div>
    </div>
  );
};

export default KioskCalendar;

import React, { useState } from "react";
import { SaveFormContainer } from "../../../styled/storage";
import { formatStorageTimes, isPayType, isStorageType } from "../../../function/kiosk";
import { Buttons, CustomInput } from "../../../styled/common";
import { useRegex } from "../../../../../../../hooks/useRegex";
import moment from "moment";

const SaveForm = ({
  transformValue,
  detail,
  handleCompleted,
  setCheckStorage,
  checkStorage,
  memo,
  setMemo,
  selectAll,
  setSelectAll,
}) => {
  const { TelFormat } = useRegex();
  const toggleCheckbox = (e) => {
    const { name } = e.target;
    const id = parseInt(name, 10);

    if (checkStorage.includes(id)) {
      setCheckStorage((data) => data.filter((item) => item !== id));
    } else {
      setCheckStorage((data) => [...data, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setCheckStorage([]);
    } else {
      const allIds = detail.map((item) => item.id);
      setCheckStorage(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleConfirm = () => {
    setSelectAll(false);
    handleCompleted({ historyIds: checkStorage, reserveId: detail[0].reserveId });
  };

  return (
    <SaveFormContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="info">보관하시겠습니까?</div>
      {detail.length > 0 && (
        <ul className="userInfo">
          <li>
            <span className="tit">이름:</span>
            <span className="con">{detail[0]?.mberNm}</span>
          </li>
          <li>
            <span className="tit">전화번호:</span>
            <span className="con">{TelFormat(detail[0]?.tel)}</span>
          </li>
          <li>
            <span className="tit">메모:</span>
            <span className="con">
              <input
                type="text"
                placeholder="메모를 입력해주세요. (최대 20자)"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                maxLength={20}
              />
            </span>
          </li>
        </ul>
      )}
      <ul className="reserved">
        {detail.length > 0 &&
          detail.map((item) => (
            <li>
              <ul className="reserveInfo">
                <li>
                  <span className="tit">보관타입</span>
                  <span className="con">{isStorageType(item.type, "label")}</span>
                </li>
                <li>
                  <span className="tit">가격</span>
                  <span className="con">{item.price.toLocaleString()}원</span>
                </li>
                <li>
                  <span className="tit">보관날짜</span>
                  <span className="con">{item.reservationDay}</span>
                </li>
                <li>
                  <span className="tit">보관시간</span>
                  <span className="con">{formatStorageTimes(item).start}</span>
                </li>
                <li>
                  <span className="tit">보관마감시간</span>
                  <span className="con">{formatStorageTimes(item).end}</span>
                </li>
                <li>
                  <span className="tit">결제여부</span>
                  <span className="con" style={{ color: item.payType ? "#d3381c" : "#000" }}>
                    {isPayType(item.payType)}
                  </span>
                </li>
              </ul>
              <CustomInput>
                <input
                  type="checkbox"
                  name={item.id}
                  checked={checkStorage.includes(parseInt(item.id, 10))}
                  onChange={toggleCheckbox}
                />
                <span className="checkmark"></span>
              </CustomInput>
            </li>
          ))}
      </ul>
      <Buttons>
        <button onClick={handleSelectAll} style={{ background: selectAll ? "#555" : "#2a3341" }}>
          {selectAll ? "전체해제" : "전체선택"}
        </button>
        <button onClick={handleConfirm}>확인</button>
      </Buttons>
    </SaveFormContainer>
  );
};

export default SaveForm;

import React from "react";
import { SalesOverviewContainer } from "../styled/salesCheck";
import moment from "moment";
import { sales } from "../function/kiosk";

const SalesOverview = ({ transformValue, detail, setViewType }) => {
  const calculateObj = (obj) => {
    return Object.values(obj)
      .reduce((acc, count) => acc + count, 0)
      .toLocaleString();
  };

  const order = [1, 2, 0];

  return (
    <SalesOverviewContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="salesDetailInfo">
        {moment(detail.createdAt).format("YYYY년 M월 D일")} 매출액
      </div>
      <ul>
        <li>
          <span className="tit">결제건수</span>
          <span className="con">{calculateObj(detail.paymentTypeCount)}</span>
        </li>
        <li>
          <span className="tit">수단별 결제건수</span>
          <span className="con">
            <ul className="payTypes">
              {order.map((key) => (
                <>
                  <li className={key}>
                    <span className="label" style={{ color: sales[key].color }}>
                      {sales[key].label}
                    </span>
                    <span style={{ color: sales[key].color }}>
                      {detail.paymentTypeCount[key] ? detail.paymentTypeCount[key] : 0}
                    </span>
                  </li>
                </>
              ))}
            </ul>
          </span>
        </li>
        <li>
          <span className="tit">취소건수</span>
          <span className="con">{calculateObj(detail.paymentTypeCancelCount)}</span>
        </li>
        <li>
          <span className="tit">수단별 취소건수</span>
          <span className="con">
            <ul className="payTypes">
              {order.map((key) => (
                <>
                  <li className={key}>
                    <span className="label" style={{ color: sales[key].color }}>
                      {sales[key].label}
                    </span>
                    <span style={{ color: sales[key].color }}>
                      {detail.paymentTypeCancelCount[key] ? detail.paymentTypeCancelCount[key] : 0}
                    </span>
                  </li>
                </>
              ))}
            </ul>
          </span>
        </li>
        <li>
          <span className="tit">결제액</span>
          <span className="con">{calculateObj(detail.paymentTypeAmount)}</span>
        </li>
        <li>
          <span className="tit">취소액</span>
          <span className="con">{calculateObj(detail.paymentTypeCancelAmount)}</span>
        </li>
        <li>
          <span className="tit">매출액</span>
          <span className="con">{detail.totalAmount.toLocaleString()}</span>
        </li>
      </ul>
      <button onClick={() => setViewType(1)}>자세히보기</button>
    </SalesOverviewContainer>
  );
};

export default SalesOverview;

import React from "react";
import { StorageDetailContainer } from "../../styled/pickUp";
import { Buttons } from "../../styled/common";

const StorageCancel = ({ transformValue, handleReserveCancel, handleBackButton }) => {
  return (
    <StorageDetailContainer
      className="slideContent center"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="modalTit">
        모든 결제가 일괄 취소됩니다.
        <br />
        진행하시겠습니까?
      </div>
      <Buttons>
        <button onClick={handleBackButton}>취소</button>
        <button onClick={handleReserveCancel}>확인</button>
      </Buttons>
    </StorageDetailContainer>
  );
};

export default StorageCancel;

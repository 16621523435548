import React, { useRef } from "react";
import { UserInfoModalContainer } from "../../styled/storage";
import { IoClose } from "react-icons/io5";
import { useRegex } from "../../../../../../hooks/useRegex";
import moment from "moment";
import "moment/locale/ko";
import { formatStorageTimes, isPayType } from "../../function/kiosk";

const UserInfoModal = ({ item, handleExitModal }) => {
  const { TelFormat } = useRegex();
  const { start, end } = formatStorageTimes(item);

  console.log(item);

  return (
    <UserInfoModalContainer>
      <div className="modal">
        <div className="modalContent">
          <div className="userInfo">
            <ul>
              <li>보관함: {item.storageId}</li>
              <li>이름: {item.mberNm}</li>
              <li>전화번호: {TelFormat(item.tel)}</li>
              <li>결제방식: {isPayType(item.payType)}</li>
              <li>결제금액: {item.price.toLocaleString()}원</li>
              <li>보관날짜: {moment(item.reservationDay).format("YYYY-MM-DD")}</li>
              <li>보관시간: {start}</li>
              <li>보관종료시간: {end}</li>
            </ul>
          </div>
          <button className="close" onClick={() => handleExitModal(false)}>
            <IoClose />
          </button>
        </div>
      </div>
    </UserInfoModalContainer>
  );
};

export default UserInfoModal;

import React from "react";
import { SalesDetailedViewContainer } from "../styled/salesCheck";
import { FaReceipt } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { extractTime, sales } from "../function/kiosk";
import * as XLSX from "xlsx";
import moment from "moment";

const SalesDetailedView = ({ transformValue, data, detail }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const isPay = {
    0: { type: "completed", label: "완료" },
    1: { type: "cancel", label: "취소" },
  };

  const downloadExcel = () => {
    const sortedData = [...data].reverse();

    const worksheet = XLSX.utils.json_to_sheet(
      sortedData.map((item, index) => ({
        순번: index + 1,
        예약번호: item.storageId,
        결제시간: extractTime(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        결제수단: sales[item.payType].label,
        결제상태: isPay[item.type].label,
        결제금액: item.price.toLocaleString() + "원",
      })),
    );
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    worksheet["!ref"] = XLSX.utils.encode_range(range);

    worksheet["!autofilter"] = { ref: `A1:F${sortedData.length + 1}` };

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet);

    const fileName = `${moment(detail.createdAt).format("YYYYMMDD")}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <SalesDetailedViewContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="box">
        {isMobile ? (
          <ul>
            {data.map((item, index) => (
              <li key={index} className="listItem">
                <div className="contentItem">
                  <span className="tit">순번</span>
                  <span className="con paymentMethod">{data.length - index}</span>
                </div>
                <div className="contentItem">
                  <span className="tit">예약번호</span>
                  <span className="con paymentMethod">{item.storageId}</span>
                </div>
                <div className="contentItem">
                  <span className="tit">결제시간</span>
                  <span className="con paymentMethod">{sales[item.payType].label}</span>
                </div>
                <div className="contentItem">
                  <span className="tit">결제수단</span>
                  <span className="con paymentMethod">{sales[item.payType].label}</span>
                </div>
                <div className="contentItem">
                  <span className="tit">결제상태</span>
                  <span className="con paymentStatus">
                    <span className={isPay[item.type].type}>{isPay[item.type].label}</span>
                  </span>
                </div>
                <div className="contentItem">
                  <span className="tit">결제금액</span>
                  <span className="con paymentAmount">{item.price.toLocaleString()}원</span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <table>
            <thead>
              <tr>
                <th>순번</th>
                <th>예약번호</th>
                <th>결제시간</th>
                <th>결제수단</th>
                <th>결제상태</th>
                <th>결제금액</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{data.length - index}</td>
                  <td>{item.storageId}</td>
                  <td>{extractTime(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                  <td>{sales[item.payType].label}</td>
                  <td className="paymentStatus">
                    <span className={isPay[item.type].type}>{isPay[item.type].label}</span>
                  </td>
                  <td>{item.price.toLocaleString()}원</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <button className="downloadExcel" onClick={downloadExcel}>
        엑셀 다운로드
      </button>
    </SalesDetailedViewContainer>
  );
};

export default SalesDetailedView;

import styled from "styled-components";

export const KioskSalesCheck = styled.div`
  padding: 1.0833vw;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  .content {
    /* height: calc(100% - 10vw - 10vh); */
    height: calc(100% - 7vw);
    width: 100%;
    padding: 0 20px;
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
  }
  .calendarInfo {
    height: 74px;
    font-size: 14px;
    /* border-bottom: 1px solid #ddd; */
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .sales {
      display: flex;
      gap: 30px;
      .salesInfo {
        min-width: 120px;
        span {
          font-size: 24px;
          display: block;
          &.tit {
            color: #777;
            font-weight: 600;
          }
          &.con {
            font-weight: 700;
          }
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px 10px;
        li {
          display: flex;
          gap: 5px;
          align-items: center;
          .dot {
            width: 15px;
            height: 15px;
            border-radius: 50%;
          }
        }
      }
    }
    .calendarAction {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        font-size: 20px;
        height: 50px;
        min-width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* background: #2a3341;
        color: #fff; */
        border-radius: 15px;
        font-weight: 700;
      }
      button {
        height: 50px;
        border: none;
        min-width: 64px;
        background: #efefef;
        /* color: #fff; */
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  .calendar {
    height: calc(100% - 84px);
    .fc {
      height: 100%;
      .fc-event[class*="total-"] {
        border-top: 1px solid #efefef !important;
        border-radius: 0;
      }
      th
        /* th,
        .fc-day */ {
        border: none;
      }
      .fc-day-sun .fc-daygrid-day-number {
        color: #d22030;
      }
      tr[role="row"] {
        height: calc(100% / 6);
      }
      .fc-day {
        &:hover {
          background-color: #efefef !important;
        }
        &.fc-day-today {
          background: none;
          .fc-daygrid-day-top a {
            position: relative;
            &::after {
              content: "오늘";
              position: absolute;
              background: tomato;
              color: #fff;
              width: 35px;
              right: 0;
              top: 50%;
              transform: translateX(100%) translateY(-50%);
              text-align: center;
              border-radius: 10px;
              height: 16px;
            }
          }
        }
      }
      .fc-event {
        background-color: transparent !important;
        border: none !important;
        color: inherit;
        font-weight: 600;
        font-size: 13px;
      }
      .fc-col-header-cell .fc-scrollgrid-sync-inner {
        background: #2a3341;
        .fc-col-header-cell-cushion {
          font-size: 14px;
          color: #fff;
          margin: 5px 0;
        }
      }
      .fc-dayGridMonth-view.fc-view.fc-daygrid {
        height: 100%;
      }
      .fc-header-toolbar {
        display: none;
      }
      .fc-daygrid-day-top {
        flex-direction: row;
        .fc-daygrid-day-number {
          font-size: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .calendarInfo {
      flex-direction: column;
      height: auto;
      margin-bottom: 0;
      padding-bottom: 0;
      .sales {
        justify-content: space-between;
        gap: 0;
        height: 45px;
        .salesInfo {
          span {
            font-size: 18px;
          }
        }
        ul {
          flex-direction: row;
          gap: 0 5px;
        }
      }
      .calendarAction {
        height: 20px;
        margin: 10px 0;
        justify-content: center;
        span {
          font-size: 16px;
          height: auto;
        }
        button {
          height: 25px;
          font-size: 14px;
          min-width: 50px;
          padding: 0 10px;
        }
      }
    }
    .calendar {
      height: calc(100% - 100px);
      .fc {
        .fc-day.fc-day-today {
          .fc-daygrid-day-top a::after {
            /* display: none; */
            content: "";
            width: 7px;
            height: 7px;
          }
        }
        .fc-event {
          font-size: 10px;
        }
      }
    }
  }
`;

export const SalesDetailModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
  }
  .slideContent {
    flex: 0 0 50%;
    height: 100%;
    position: relative;
    transform: translateX(0);
    flex-basis: 100%;
    flex-shrink: 0;
    transition: 0.3s;
    width: 100%;
  }
`;

export const SalesOverviewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  > ul {
    border-top: 1px solid #999;
    > li {
      border-bottom: 1px solid #999;
      font-size: 30px;
      display: flex;
      gap: 10px;
      align-items: center;
      span {
        &.tit {
          width: 40%;
          background: #efefef;
          padding: 10px 0 10px 10px;
        }
        &.con {
          width: 60%;
          display: flex;
          gap: 10px;
          align-items: center;
          position: relative;
          justify-content: space-between;
          .payTypes {
            display: flex;
            align-items: center;
            li {
              display: flex;
              align-items: center;
              margin-right: 10px;
              &:last-child {
                margin-right: 0;
              }
            }
            .dot {
              display: inline-block;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 5px;
            }
            .label {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  button {
    background: #2a3341;
    width: 200px;
    color: #fff;
    border: none;
    margin-left: auto;
    border-radius: 15px;
    font-size: 28px;
    padding: 10px 20px;
  }
  @media screen and (max-width: 767px) {
    > ul {
      height: calc(100% - 130px);
      overflow-y: auto;
      > li {
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 10px;
        border-bottom: none;
        span {
          font-size: 16px;
          &.tit {
            width: 100%;
            text-align: center;
            padding: 7px 0;
          }
          &.con {
            padding: 10px 0;
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
`;

export const SalesDetailedViewContainer = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  .box {
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    margin-bottom: auto;
    &::-webkit-scrollbar {
      width: 10px;
      margin-left: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 10px;
    }
    /* 공통 */
    .paymentStatus span {
      display: inline-block;
      padding: 2px 5px;
      border: 2px solid #000;
      font-size: 14px;
      font-weight: 700;
      &.cancel {
        color: #d52727;
        border-color: #d52727;
      }
      &.completed {
        color: #2e5aac;
        border-color: #2e5aac;
      }
    }
    /*  */
  }
  table {
    width: 100%;
    border-collapse: collapse;
    max-height: calc(100% - 65px);
    font-size: 16px;
    th,
    td {
      text-align: center;
      border: 1px solid #d9d9d9;
    }
    th {
      padding: 10px 0;
      background: #5a5a5a;
      color: #fff;
    }
    td {
      padding: 5px 0;
    }
    tbody {
      font-weight: normal;
    }
  }
  button.downloadExcel {
    background: #2a3341;
    width: 200px;
    color: #fff;
    border: none;
    margin-left: auto;
    border-radius: 15px;
    font-size: 28px;
    padding: 10px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 15px;
    .box ul {
      margin: 0 10px;
      li {
        border: 2px solid #ddd;
        padding: 10px;
        border-radius: 15px;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .contentItem {
          span {
            font-size: 14px;
            line-height: auto;
            &.tit {
              display: inline-block;
              margin-right: 10px;
              font-weight: normal;
              min-width: 65px;
            }
            &.con {
            }
          }
        }
      }
    }
  }
`;

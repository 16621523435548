import React, { useEffect, useState } from "react";
import axios, { all } from "axios";
import { CustResvContainer } from "../styled/storage";
import { Modal, ModalCloseButton } from "../styled/common";
import CustomerList from "./readyPickupModal/customerList";
import { IoClose } from "react-icons/io5";
import { TbArrowBackUp } from "react-icons/tb";
import AddPayment from "./editStatusModal/addPayment";
import StorageDetails from "./editStatusModal/storageDetails";
import { toast } from "react-toastify";
import AddServiceTime from "./editStatusModal/addServiceTime";

const ReadyPickupModal = ({ handleModalClose }) => {
  const [data, setData] = useState([]);
  const [storage, setStorage] = useState({});
  const [origin, setOrigin] = useState([]);
  const [viewType, setViewType] = useState(0);
  const [searched, setSearched] = useState("");
  const [details, setDetails] = useState([]);
  const [checked, setChecked] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [extraCharges, setExtraCharges] = useState(0);
  const [selected, setSelected] = useState({});
  const [payType, setPayType] = useState("1");

  const transformValue = viewType * -100;
  const isValidStatus = (status) => ["READY"].includes(status);

  useEffect(() => {
    getReqCustomer();
  }, []);

  useEffect(() => {
    const categorizedData = details.reduce(
      (acc, item) => {
        const type = item.type;
        if (type >= 0 && type <= 2) {
          acc[type].items.push(item);
        }
        return acc;
      },
      { 0: { items: [] }, 1: { items: [] }, 2: { items: [] } },
    );

    setStorage(categorizedData);
  }, [details]);

  // 픽업 요청 조회
  const getReqCustomer = async () => {
    const url = "/api/request-user";
    const body = {};

    const res = await axios.post(url, body);

    if (res.status === 200) {
      setOrigin(res.data);
      setData(res.data);
    }
  };

  // 상세보기
  const getEnableStorageDetail = async (id) => {
    const url = "/api/reserve-user-detail";
    const body = { id };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      return res.data;
    }
  };

  const handleBackButton = () => {
    switch (viewType) {
      case 1:
        handleReset();
        setViewType(0);
        break;
      case 0:
        handleModalClose();
        break;
      case 3:
        setViewType(1);
        break;
      default:
        handleReset();
        setViewType((type) => type - 1);
        break;
    }
  };

  const handleReset = () => {
    setChecked([]);
    setIsAllSelected(false);
    setExtraCharges(0);
  };

  const handleSearch = () => {
    setData(
      origin.filter((item) => {
        return item.tel && item.tel.includes(searched);
      }),
    );
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setSearched(value);
    }
  };

  // 상세보기
  const handleClickCustomer = async (item) => {
    const detail = await getEnableStorageDetail(item.id);
    setSelected(item);
    setDetails(detail);

    if (!detail || !detail.length) return;
    setViewType(1);
  };

  // 체크박스 변경
  const toggleCheckbox = (e) => {
    const id = parseInt(e.target.name, 10);
    const selectedItem = details.find((item) => item.id === id);

    if (!selectedItem || !isValidStatus(selectedItem.reservationStatus)) {
      return;
    }

    setChecked((prevChecked) =>
      prevChecked.some((item) => item.id === id)
        ? prevChecked.filter((item) => item.id !== id)
        : [...prevChecked, selectedItem],
    );
  };

  // 픽업 요청 후 추가금 여부 확인
  const checkAddPay = () => {
    if (!checked || !checked.length) {
      toast.error("픽업상태를 변경할 보관함을 선택해주세요.");
      return;
    }

    const hasAddPay = checked.some((item) => !item.addPayTof && item.addPay);

    if (hasAddPay) {
      const total = checked.reduce((total, item) => {
        return total + (item.addPay || 0);
      }, 0);

      setExtraCharges(total);
      setViewType(2);
    } else {
      handlePickUp();
    }
  };

  // 픽업하기
  const handlePickUp = async () => {
    const url = "/api/pickup";
    const body = { historyIds: checked.map((item) => item.id), reserveId: checked[0].reserveId };
    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success("픽업이 완료되었습니다.");
        handleModalClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 전체 선택 및 해제
  const toggleSelectAll = () => {
    const allItems = Object.values(storage)
      .map((storageGroup) => storageGroup.items)
      .flat();

    if (isAllSelected) {
      setChecked([]);
    } else {
      const allValidItems = allItems.filter((item) => isValidStatus(item.reservationStatus));
      setChecked(allValidItems);
    }

    setIsAllSelected((prev) => !prev);
  };

  // 추가금 결제
  const handleAddPay = async () => {
    const url = "/api/add-pay";
    const body = {
      historyIds: checked.map((item) => item.id),
      reserveId: checked[0].reserveId,
      addPayType: payType,
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        handlePickUp();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAllItem = () => {
    const allItems = Object.values(storage)
      .map((storageGroup) => storageGroup.items)
      .flat();
    return allItems.filter((item) => isValidStatus(item.reservationStatus));
  };

  // 서비스 버튼 클릭 시
  const handleServiceTime = () => {
    const allValidItems = handleAllItem();
    if (!allValidItems.length) {
      toast.error("서비스 시간을 추가할 보관함이 존재하지 않습니다.");
      return;
    }
    setViewType(3);
  };

  // 서비스 시간 추가
  const handleService = async () => {
    const allValidItems = handleAllItem();
    const historyIds = allValidItems.map((item) => item.id);

    const url = "/api/service-time";
    const body = { historyIds, reserveId: allValidItems[0].reserveId };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        toast.success("서비스 시간이 추가되었습니다.");
        handleClickCustomer(selected);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CustResvContainer>
      <Modal>
        <div className="modalContent">
          <ModalCloseButton className="close" onClick={handleBackButton}>
            {!!viewType ? <TbArrowBackUp /> : <IoClose />}
          </ModalCloseButton>
          <div className="slideWrapper">
            <CustomerList
              transformValue={transformValue}
              data={data}
              handleSearch={handleSearch}
              handleInputChange={handleInputChange}
              searched={searched}
              handleClickCustomer={handleClickCustomer}
            />
            <StorageDetails
              selected={selected}
              details={details}
              storage={storage}
              handleCheckRadio={checkAddPay}
              toggleSelectAll={toggleSelectAll}
              isAllSelected={isAllSelected}
              checkStorage={checked}
              toggleCheckbox={toggleCheckbox}
              transformValue={transformValue}
              statusType="READY"
              handleServiceTime={handleServiceTime}
            />
            <AddPayment
              transformValue={transformValue}
              extraCharges={extraCharges}
              setViewType={setViewType}
              handleAddPay={handleAddPay}
              payType={payType}
              setPayType={setPayType}
            />
            <AddServiceTime
              transformValue={transformValue}
              handleService={handleService}
              handleBackButton={handleBackButton}
            />
          </div>
        </div>
      </Modal>
    </CustResvContainer>
  );
};

export default ReadyPickupModal;

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import axios from "axios";

import KioskHeader from "./component/common/kioskHeader";

import { Container } from "./component/styled/common";
import { KioskSalesCheck } from "./component/styled/salesCheck";
import { sales, useDisableScroll } from "./component/function/kiosk";

import SalesDetailModal from "./component/kioskSalesCheck/salesDetailModal";
import CalendarInfo from "./component/kioskSalesCheck/calendarInfo";
import Calendar from "./component/kioskSalesCheck/calendar";

const KioskSalesCheckPage = () => {
  useDisableScroll();

  const calendarRef = useRef();
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [onDate, setOnDate] = useState(moment().format("YYYY-MM-DD"));
  const [onPrevDate, setOnPrevDate] = useState(moment().format("YYYY-MM-DD"));
  const [openModal, setOpenModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getSalesInfo(onDate);
  }, []);

  useEffect(() => {
    updateCalendarView(onDate);
    if (onDate && (!onPrevDate || !moment(onDate).isSame(onPrevDate, "month"))) {
      getSalesInfo(onDate);
    }
    setOnPrevDate(onDate);
  }, [onDate]);

  // 날짜 변경
  const updateCalendarView = (date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
    }
  };

  // 매출 조회 - 월별
  const getSalesInfo = async (date) => {
    const url = "/api/sales-info/month";
    const body = {
      month: moment(date).format("MM"),
      year: moment(date).format("YYYY"),
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200 && res.data.length > 0) {
        setData(res.data);
        const event = transformEvents(res.data);
        setEvents(event);
      } else {
        setData([]);
        setEvents([]);
        setTotal(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 풀캘린더 형식에 변경
  const transformEvents = (origin) => {
    const events = [];
    let month = 0;

    origin.forEach((item) => {
      let totalAmount = 0;

      const paymentTypes = Object.keys(item.paymentTypeAmount);
      paymentTypes.forEach((type) => {
        const amount = item.paymentTypeAmount[type];
        const cancel = item.paymentTypeCancelAmount[type];

        const total = cancel ? amount - cancel : amount;
        totalAmount += total;

        events.push({
          title: `${total.toLocaleString()}`,
          start: item.createdAt,
          textColor: sales[type].color || "#000",
          payType: sales[type].type,
          allDay: true,
        });
      });

      events.push({
        id: `total-${item.createdAt}`,
        title: `${totalAmount.toLocaleString()}`,
        start: item.createdAt,
        textColor: "#000",
        payType: "total",
        allDay: true,
      });

      month += totalAmount;
    });

    setTotal(month);
    return events;
  };

  const handleEventClick = (date) => {
    const event = data.find((item) => item.createdAt === date);
    if (!event) return;

    setDetail(event);
    setOpenModal(true);
  };

  const handleDateClick = async (date) => {
    const target = moment(date).format("YYYY-MM-DD");
    setOnDate(target);
  };

  return (
    <Container>
      <KioskSalesCheck>
        <KioskHeader title={"매출관리"} />
        <div className="content">
          <CalendarInfo onDate={onDate} handleDateClick={handleDateClick} total={total} />
          <Calendar calendarRef={calendarRef} handleEventClick={handleEventClick} events={events} />
        </div>
      </KioskSalesCheck>
      {openModal && <SalesDetailModal setOpenModal={setOpenModal} detail={detail} />}
    </Container>
  );
};

export default KioskSalesCheckPage;

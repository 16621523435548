import React, { useEffect, useRef, useState } from "react";
import { Header } from "../styled/common";
import { IoRefresh, IoSearch } from "react-icons/io5";
import { IoIosRefresh, IoMdRefresh } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { isSScenterMbers } from "../function/kiosk";
import KioskCalendar from "./kioskCalendar";

const KioskHeader = ({
  title,
  handleSearch = null,
  searched = "",
  setSearched = null,
  dateRange = null,
  openCalendar = null,
  handleOpenCalendar = null,
  handleDateClick = null,
  handleResetDate = null,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector((state) => state?.user?.data[0]);

  return (
    <Header>
      <div className="tit">으능&성심 {title}</div>
      {pathname.includes("reservationStatus") && (
        <div className="resStatusDate">
          <button onClick={() => handleOpenCalendar(true)}>
            {dateRange?.start && dateRange?.end
              ? `${dateRange.start}~${dateRange.end}`
              : "날짜 선택"}
          </button>
          {openCalendar && (
            <KioskCalendar
              handleDateClick={handleDateClick}
              handleOpenCalendar={handleOpenCalendar}
              dateRange={dateRange}
              handleResetDate={handleResetDate}
            />
          )}
        </div>
      )}
      {handleSearch && (
        <div className="searchForm">
          <input
            type="text"
            value={searched}
            onChange={(e) => setSearched(e.target.value)}
            onKeyDown={(e) => handleEnterKey(e, handleSearch)}
          />
          <button onClick={handleSearch}>검색</button>
        </div>
      )}
      <div className="close">
        {pathname.includes("pickup") && (
          <button onClick={() => window.location.reload()}>
            <IoMdRefresh />
          </button>
        )}
        {!isSScenterMbers(user?.mberNo) && (
          <button onClick={() => navigate("/mypage/kiosk")}>
            <IoClose />
          </button>
        )}
      </div>
    </Header>
  );
};

export default KioskHeader;

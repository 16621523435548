import React from "react";
import { Table } from "../styled/common";
import {
  extractTime,
  formatDate,
  isPayType,
  isPlatform,
  isStorageType,
  resStatus,
} from "../function/kiosk";
import { useRegex } from "../../../../../hooks/useRegex";

const KioskTable = ({ data = [], type, handleModalOpen = null, isDateVisible, status }) => {
  const { TelFormat } = useRegex();
  const isResStatus = type === "resStatus";

  const TabelHeader = () => (
    <li>
      <div className="index">
        {type === "calculate" ? (
          <label>
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        ) : (
          <span>예약번호</span>
        )}
      </div>
      <div className="name">
        <span>이름</span>
      </div>
      <div className="tel">
        <span>전화번호</span>
      </div>
      {isResStatus && (
        <>
          <div className="price">
            <span>가격</span>
          </div>
          <div className="status">
            <span>{isResStatus ? "픽업상태" : "결제상태"}</span>
          </div>
        </>
      )}
      <div className="type">
        <span>플랫폼</span>
      </div>
      <div className="type">
        <span>결제방식</span>
      </div>
      <div className="duration">
        <span>보관날짜</span>
      </div>
      <div className="time">
        <span>보관시작시간</span>
      </div>
      {isResStatus && (
        <>
          <div className="time">
            <span>픽업시간</span>
          </div>
        </>
      )}

      <div className="memo">메모</div>
      <div className="actions"></div>
    </li>
  );

  const Row = ({ item }) => (
    <li>
      <div className="index">
        {type === "calculate" ? (
          <label>
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        ) : (
          <span>{item.id}</span>
        )}
      </div>
      <div className="name">
        <span>{item.mberNm}</span>
      </div>
      <div className="tel">
        <span>{TelFormat(item.tel)}</span>
      </div>
      {isResStatus && (
        <>
          <div className="price">
            <span>{item.price ? item.price.toLocaleString() : 0}원</span>
          </div>
          <div className="status">
            <span style={{ color: resStatus[item.reservationStatus]?.color }}>
              {resStatus[item.reservationStatus]?.label}
            </span>
          </div>
        </>
      )}
      <div className="type">
        <span>{isPlatform(item?.os)}</span>
      </div>
      <div className="type">
        <span>{isPayType(item.payType)}</span>
      </div>
      <div className="duration">
        <span>{formatDate(item.reservationDay)}</span>
      </div>
      <div className="time">
        <span>{item.reservationStartTime ? item.reservationStartTime : ""}</span>
      </div>
      {isResStatus && (
        <div className="time">
          <span>{item.updateAt ? extractTime(item.updateAt).format("a hh:mm") : ""}</span>
        </div>
      )}

      <div className="memo">
        <span>{item.memo ? item.memo : ""}</span>
      </div>
      <div className="actions">
        {!isResStatus ? (
          <button onClick={() => handleModalOpen("COMPLETED", item)}>관리</button>
        ) : (
          <button onClick={() => handleModalOpen(item)}>상세보기</button>
        )}
      </div>
    </li>
  );

  return (
    <Table
      className={type}
      style={{
        height: data.length > 0 ? "auto" : "100%",
      }}>
      <TabelHeader />
      {data.length > 0 ? (
        data.map((item) => <Row key={item.id} item={item} />)
      ) : (
        <li className="notUser">사용자가 존재하지 않습니다.</li>
      )}
    </Table>
  );
};

export default KioskTable;

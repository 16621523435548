import React from "react";
import {
  checkAdditionalFee,
  formatStorageTimes,
  isStorageType,
  isValidStatus,
  resStatus,
} from "../../function/kiosk";
import { CustomInput } from "../../styled/common";
import moment from "moment";

const UserStorage = ({ typeKey, storage, checkStorage, toggleCheckbox, statusType }) => {
  const isItemChecked = (item) => {
    const { reservationStatus: status } = item;
    if (!isValidStatus(status) || (statusType === "READY" && status !== "READY")) {
      return false;
    }
    return true;
  };

  return (
    <div key={typeKey} className={`content ${isStorageType(parseInt(typeKey), "key")}`}>
      <h3>{isStorageType(parseInt(typeKey), "label")}</h3>
      <ul className="userStorage">
        {storage[typeKey].items.map((item) => (
          <li key={item.id} style={{ opacity: isItemChecked(item) ? 1 : 0.5 }}>
            <CustomInput className="block">
              <input
                type="checkbox"
                name={item.id}
                checked={checkStorage.some((checkedItem) => checkedItem.id === item.id)}
                onChange={toggleCheckbox}
              />
              <span className="checkmark"></span>
            </CustomInput>
            <ul>
              <li>
                <span className="tit">보관함번호</span>
                <span className="con">{item.storageId}</span>
              </li>
              <li>
                <span className="tit">가격</span>
                <span className="con">{item.price ? item.price.toLocaleString() : 0}원</span>
              </li>
              <li>
                <span className="tit">픽업상태</span>
                <span
                  className="status con"
                  style={{ color: resStatus[item.reservationStatus]?.color }}>
                  {resStatus[item.reservationStatus]?.label}
                </span>
              </li>
              <li>
                <span className="tit">보관날짜</span>
                <span className="con">{item?.reservationDay}</span>
              </li>
              <li>
                <span className="tit">보관시간</span>
                <span className="con">{formatStorageTimes(item)?.start}</span>
              </li>
              <li>
                <span className="tit">보관종료시간</span>
                <span className="con">{formatStorageTimes(item)?.end}</span>
              </li>
              <li>
                <span className="tit">추가요금여부</span>
                <span className="con" style={{ color: checkAdditionalFee(item) ? "#d52727" : "" }}>
                  {checkAdditionalFee(item) ? "O" : "X"}
                </span>
              </li>
              <li>
                <span className="tit">추가요금결제여부</span>
                {checkAdditionalFee(item) && (
                  <span className="con">{item.addPayTof ? "O" : "X"}</span>
                )}
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserStorage;

import React from "react";
import UserStorage from "./userStorage";
import { Buttons } from "../../styled/common";
import { StorageDetailContainer } from "../../styled/pickUp";
import { useRegex } from "../../../../../../hooks/useRegex";

const StorageDetails = ({
  selected,
  details,
  status = null,
  action = "",
  handleChangeAction = null,
  storage,
  toggleSelectAll,
  handleCheckRadio,
  isAllSelected,
  checkStorage,
  toggleCheckbox,
  transformValue,
  statusType,
  handleServiceTime = null,
}) => {
  const { TelFormat } = useRegex();

  return (
    <StorageDetailContainer
      className="slideContent between"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="modalTit">{statusType === "READY" ? "픽업요청확인 " : "픽업상태"}</div>
      <div className="userInfo">
        <ul>
          <li>
            <span className="tit">예약번호: </span>
            <span className="con">{selected?.id}</span>
          </li>
          <li>
            <span className="tit">이름: </span>
            <span className="con">{selected?.mberNm}</span>
          </li>
          {selected?.tel && (
            <li>
              <span className="tit">전화번호: </span>
              <span className="con">{TelFormat(selected.tel)}</span>
            </li>
          )}
          {selected?.memo && (
            <li>
              <span className="tit">메모: </span>
              <span className="con">{selected.memo}</span>
            </li>
          )}
        </ul>

        <div className="actions">
          {statusType !== "READY" &&
            Object.entries(status).map(([key, item]) => (
              <label key={key}>
                <input
                  type="radio"
                  name="status"
                  onChange={() => handleChangeAction(key)}
                  checked={action === key}
                />
                <span>{item.label}</span>
              </label>
            ))}
          <button onClick={() => handleServiceTime()}>서비스</button>
        </div>
      </div>
      <div className={`storages ${statusType}`}>
        {Object.keys(storage).map((typeKey) =>
          storage[typeKey].items.length > 0 ? (
            <UserStorage
              typeKey={typeKey}
              storage={storage}
              checkStorage={checkStorage}
              toggleCheckbox={toggleCheckbox}
              statusType={statusType}
            />
          ) : null,
        )}
      </div>
      <Buttons>
        <button
          style={{ background: isAllSelected ? "#555" : "#2a3341" }}
          onClick={toggleSelectAll}>
          {isAllSelected ? "전체해제" : "전체선택"}
        </button>

        <button onClick={handleCheckRadio} className="submit">
          확인
        </button>
      </Buttons>
    </StorageDetailContainer>
  );
};

export default StorageDetails;
